import React from 'react'
import { Link } from 'react-router-dom';

const Countertop = ( {featuredPhotos} ) => {
  return (
    <div className='feature-item'>
        <Link to={'/countertops'} style={{ textDecoration: 'none' }}>
          <h2>Countertops</h2>
          <img src={featuredPhotos[1]} alt= "featured-countertop" height="200"/>
        </Link>
    </div>
  )
}

export default Countertop;