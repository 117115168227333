import React from 'react'
import { Link } from 'react-router-dom'

const Countertops = ( {counterTopPhotos} ) => {
    
    return (
        <div>
            <div className='product-header'>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <h2>Home</h2>
                </Link>
                <h1>Countertops</h1>           
            </div>
            <div className='product-description'>
                <p>Performance products for todays active lifestyles, with designer elegance. No maintenance to worry about either.</p>
            </div>
            <div className='all-products'>
            {counterTopPhotos.map((image, index) => (
                <div className='product-item' key = {image}>
                    <img src={image} key={index} alt={`Image ${index}`} width="300"/>
                </div>
            ))}
            </div>
        </div>
      );
}

export default Countertops;
