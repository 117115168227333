import React from 'react'
import { Link } from 'react-router-dom';

const Smartglass = ( {featuredPhotos} ) => {
  return (
    <div className='feature-item'>
        <Link to={'/smartglass'} style={{ textDecoration: 'none' }}>
            <h2>Smart Glass</h2>
            <img src={featuredPhotos[4]} alt= "featured-smartglass" height="200"/>
        </Link>
    </div>
  )
}

export default Smartglass;