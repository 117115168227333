import React, { useState, useEffect } from 'react';
import './App.css';
import Homepage from './components/Homepage';
import Countertops from './containers/Countertops';
import Doors from './containers/Doors';
import Ironworks from './containers/Ironworks';
import Woodfloors from './containers/Woodfloors';
import Smartglasses from './containers/Smartglasses';
import { Route, Routes } from 'react-router-dom';


function App() {

  const [isLoading, setisLoading] = useState(true);
  const [featuredPhotos, setFeaturedPhotos] = useState([]);
  const [counterTopPhotos, setCounterTopPhotos] = useState([]);
  const [oldWorldDoorPhotos, setoldWorldDoorPhotos] = useState([]);
  const [modernDoorPhotos, setModernDoorPhotos] = useState([]);
  const [ironworkPhotos, setIronworkPhotos] = useState([]);
  const [smartGlassPhotos, setSmartGlassPhotos] = useState([]);
  const [woodFloorPhotos, setwoodFloorPhotos] = useState([]);

  useEffect(() => {
    const featuredImagePath = require.context('./assets/Featured', false, /\.(jpe?g|png)$/i);
    const featuredImages = featuredImagePath.keys().map(featuredImagePath);
    setFeaturedPhotos(featuredImages);

    const counterImagePaths = require.context('./assets/Countertops', false, /\.jpg$/i);
    const countertopImages = counterImagePaths.keys().map(counterImagePaths);
    setCounterTopPhotos(countertopImages);

    const imagePathsModern = require.context('./assets/Doors/Modern', false, /\.jpg$/i);
    const modernDoorImages = imagePathsModern.keys().map(imagePathsModern);
    setModernDoorPhotos(modernDoorImages);

    const imagePathsOldWorld = require.context('./assets/Doors/OldWorld', false, /\.jpg$/i);
    const oldWorldDoorImages = imagePathsOldWorld.keys().map(imagePathsOldWorld);
    setoldWorldDoorPhotos(oldWorldDoorImages)

    const ironworkImagePaths = require.context('./assets/Ironwork', false, /\.jpg$/i);
    const ironworkImages = ironworkImagePaths.keys().map(ironworkImagePaths);
    setIronworkPhotos(ironworkImages)

    const smartGlassimagePaths = require.context('./assets/Smartglass', false, /\.jpeg$/i);
    const smartGlassImages = smartGlassimagePaths.keys().map(smartGlassimagePaths);
    setSmartGlassPhotos(smartGlassImages)

    const woodFloorimagePaths = require.context('./assets/Woodfloors', false, /\.jpg$/i);
    const woodFloorImages = woodFloorimagePaths.keys().map(woodFloorimagePaths);
    setwoodFloorPhotos(woodFloorImages)

    setisLoading(false)
  }, [])

  if (isLoading) return <h1>Loading...</h1>

  return (
    <div className="App">
      <Routes>
          <Route exact path="/" element={<Homepage featuredPhotos = {featuredPhotos}/>} />
          <Route exact path="/countertops" element={<Countertops counterTopPhotos = {counterTopPhotos}/>} />
          <Route exact path="/doors" element={<Doors oldWorldDoorPhotos = {oldWorldDoorPhotos} modernDoorPhotos = {modernDoorPhotos}/>} />
          <Route exact path="/ironwork" element={<Ironworks ironworkPhotos = {ironworkPhotos}/>} />
          <Route exact path="/woodfloors" element={<Woodfloors woodFloorPhotos = {woodFloorPhotos} />} />
          <Route exact path="/smartglass" element={<Smartglasses smartGlassPhotos = {smartGlassPhotos} />} />
      </Routes>
    </div>
  );
}

export default App;