import React from 'react'
import { Link } from 'react-router-dom'

const Woodfloors = ( {woodFloorPhotos} ) => {
    return (
        <div>
            <div className='product-header'>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <h2>Home</h2>
                </Link>
                <h1>Woodfloors</h1>           
            </div>
            <div className='product-description'>
                <p>Beautiful, soft looks that perform in any room. </p>
            </div>
            <div className='all-products'>
            {woodFloorPhotos.map((image, index) => (
                <div className='product-item' key = {image}>
                    <img src={image} key={index} alt={`Image ${index}`} width="300"/>
                </div>
            ))}
            </div>
        </div>
      );
}

export default Woodfloors;
