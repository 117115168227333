import React from 'react'

const Contact = () => {

    const jon = {
        name: 'Jon Lake', 
        email: 'whartonsinteriors@gmail.com', 
        phone: '(714)909-5315'
    }

  return (
    <div className='contact'>
        <h2>Contact Us</h2>
        <div className='contact-info'>
                <h3>Owner: {jon.name}</h3>
                <h3>Phone: {jon.phone}</h3>
                <h3>Email: {jon.email}</h3>
        </div>
    </div>
  )
}

export default Contact;