import React from 'react'
import { Link } from 'react-router-dom'

const Smartglasses = ( {smartGlassPhotos} ) => {
    return (
        <div>
            <div className='product-header'>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <h2>Home</h2>
                </Link>
                <h1>Smart Glass</h1>           
            </div>
            <div className='product-description'>
                <p>From clear to frosted with a flick of a switch! Our smart film is a dream for those that like to let the sun in, and turn it off as needed.</p>
            </div>
            <div className='all-products'>
            {smartGlassPhotos.map((image, index) => (
                <div className='product-item' key = {image}>
                    <img src={image} key={index} alt={`Image ${index}`} width="300"/>
                </div>
            ))}
            </div>
        </div>
      );
}

export default Smartglasses;
