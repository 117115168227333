import React from 'react'
import { Link } from 'react-router-dom'

const Ironworks = ( {ironworkPhotos} ) => {
    return (
        <div>
            <div className='product-header'>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <h2>Home</h2>
                </Link>
                <h1>Ironworks</h1>           
            </div>
            <div className='product-description'>
                <p>The perfect accents to any door, window, or home feature. Got a design…draw it. We can price it based on your dimensions or we can design it for you.</p>
            </div>
            <div className='all-products'>
            {ironworkPhotos.map((image, index) => (
                <div className='product-item' key = {image}>
                    <img src={image} key={index} alt={`Image ${index}`} width="300"/>
                </div>
            ))}
            </div>
        </div>
      );
}

export default Ironworks;
