import React from 'react'
import { Link } from 'react-router-dom';
import featuredDoor from '../assets/Featured/IMG_0831.JPG'


const Door = ( {featuredPhotos} ) => {
  return (
    <div className='feature-item'>
        <Link to={'/doors'} style={{ textDecoration: 'none' }}>
          <h2>Doors</h2>
          <img src={featuredPhotos[0]} alt= "featured-door" height="200"/>
        </Link>
    </div>
  )
}

export default Door;