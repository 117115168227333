import React from 'react'
import Countertop from './Countertop';
import Woodfloor from './Woodfloor';
import Door from './Door';
import Ironwork from './Ironwork';
import Contact from './Contact';
import Smartglass from './Smartglass';
import Logo from '../assets/Whartons-Logos/color-logo-no-background.png';

const Homepage = ( {featuredPhotos} ) => {
  return (
    <div className='homepage'>
        <img src={Logo} alt= "wharton-logo" width="500"/>
        <div className='endpoints'>
            <Door featuredPhotos = {featuredPhotos}/>
            <Countertop featuredPhotos = {featuredPhotos}/>
            <Woodfloor featuredPhotos = {featuredPhotos}/>
            <Ironwork featuredPhotos = {featuredPhotos}/>
            <Smartglass featuredPhotos = {featuredPhotos}/>
        </div>
        <Contact />
    </div>
  )
}

export default Homepage;
