import React from 'react'
import { Link } from 'react-router-dom';

const Ironwork = ( {featuredPhotos} ) => {
  return (
    <div className='feature-item'>
        <Link to={'/ironwork'} style={{ textDecoration: 'none' }}>
            <h2>Ironwork</h2>
            <img src={featuredPhotos[3]} alt= "featured-ironwork" height="200"/>
        </Link>
    </div>
  )
}

export default Ironwork;