import React from 'react'
import { Link } from 'react-router-dom';

const Woodfloor = ( {featuredPhotos} ) => {
  return (
    <div className='feature-item'>
        <Link to={'/woodfloors'} style={{ textDecoration: 'none' }}>
          <h2>Wood Floors</h2>
          <img src={featuredPhotos[2]} alt= "featured-woodfloor" width="500" height="200"/>
        </Link>
    </div>
  )
}

export default Woodfloor;